@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap");
.me-auto {
  margin-left: 60%;
  color: #a020f0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1.5625rem;
}
.me-auto:hover {
  color: #926f34;
}
a {
  text-decoration: none;
}
.Links {
  text-decoration: none;
  color: #a020f0;
}
.me-auto .Links :hover {
  border-bottom: 1px solid #926f34;
}
.container img {
  max-width: 5rem;
  min-height: 2.5625rem;
  left: 0;
}
.container button {
  width: 4rem;
  margin-left: 50%;
  margin-top: -2%;
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Header {
  background: url(./Media/heading.jpeg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
  filter: blur(4px);
}
.header {
  width: 100%;
  height: 60%;
}

.churchName {
  margin: 0 auto;
  text-align: center;
  padding-top: 15rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: blue;
}
.churchName h3 {
  font-size: 2rem;
  font-weight: 500;
  color: blue;
  margin: 1rem;
}
.churchName h2 {
  font-size: 3rem;
  color: blue;
}
.Welcome {
  max-width: 61rem;
  margin: 0 auto;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: justify;
  align-items: center;
  font-weight: 500;
}
.Welcome h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  color: #926f34;
  border-bottom: 1px solid #926f34;
  text-align: left;
  text-transform: capitalize;
  width: 90%;
}
.Welcome h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  font-weight: 200;
  color: #926f34;
  text-align: left;
  text-transform: capitalize;
}
.Welcome h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  padding: 2.5rem;
  font-size: 0.875rem;
  line-height: 1.5625rem;
  color: #000;
}
.link {
  width: 18.75rem;
  height: 2.25rem;
  border: 1px solid black;
  padding: 0.5rem;
  margin: 0 auto;
  margin-top: 2rem;
  background-color: #926f34;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  border-radius: 0.625rem;
  text-align: center;
  text-decoration: none;
  border: none;
}
button,
.maglink {
  width: 18.75rem;
  height: 2.25rem;
  padding: 0.5rem;
  margin: 0 auto;
  text-decoration: none;
  text-align: center;
  margin-top: 2rem;
  background-color: #926f34;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  border-radius: 0.625rem;
  align-items: center;
  border: none;
}

.Watchus {
  max-width: 61rem;
  padding: 2.5rem;
  margin: 0 auto;
}
.services {
  max-width: 61rem;
  padding: 2.5rem;
  margin: 0 auto;
}
.services h2 {
  font-family: "Montserrat", sans-serif;
  color: #926f34;
  border-bottom: 1px solid #926f34;
  text-align: left;
  text-transform: capitalize;
  width: 100%;
}
.services h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5625rem;
  font-weight: 500;
  text-align: justify;
  color: #926f34;
}
.Watchus h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 3rem;
  font-weight: 500;
  color: #926f34;
  border-bottom: 1px solid #926f34;
  text-align: left;
  text-transform: capitalize;
  width: 100%;
}
.socials {
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
}
.yTube {
  padding-top: 2rem;
}
.yTube img {
  width: 12.5rem;
  height: 5.845625rem;
}
.yTubeInfo {
  float: right;
  text-align: center;
}
.tv img {
  width: 12.5rem;
  height: 12.5rem;
}
.tvInfo {
  float: right;
}
.sunday {
  max-width: 27.1875rem;
  text-align: justify;
}
.order h6 {
  margin-top: 2.5rem;
}
.midweek {
  max-width: 27.1875rem;
}
.order {
  display: flex;
  flex-direction: row;
  max-width: 61rem;
  justify-content: space-between;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}
.channel {
  width: 100%;
  height: 1.875rem;
  display: flex;
  margin: 1rem;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.channel img {
  width: 1.5rem;
  height: 0.125rem;
}
.Blog h2 {
  font-family: "Montserrat", sans-serif;

  color: #926f34;
  border-bottom: 1px solid #926f34;
  text-align: left;
  text-transform: capitalize;
}
.Blog img {
  max-width: 31.5625rem;
  height: 15.375rem;

  border-radius: 1.25rem;
}
.Blog {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0px;
  gap: 2.5rem;
  margin: 0 auto;
  max-width: 61rem;
}
.blogInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem -1rem;
  gap: 1.25rem;
  max-width: 61rem;
  border-radius: 1.25rem;
}
.blogText h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-align: center;
  color: #926f34;
}
.blogText h4 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  color: #926f34;
}
.blogText h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}

@media (min-width: 1000px) {
  .link {
    margin-left: 2rem;
  }
  .sunday {
    margin-right: 3rem;
  }
  .Blog {
    padding-right: 1rem;
  }
}
@media (min-width: 1920px) {
  .Watchus,
  .services,
  .content,
  .Welcome,
  .order,
  .blog,
  .cards {
    width: 80rem;
  }
}
